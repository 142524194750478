.navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  white-space: nowrap;
  scrollbar-width: none;
  scroll-behavior: smooth;
  width: 100%;
  height: 55px;
  top: 64px;
  overflow-x: scroll;
  background-color: #ffffff;
  z-index: 1000;
  border-radius: 20px;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.1);

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4f4f4f;
    gap: 5px;
    width: 100%;
    min-height: 40px;
    border-radius: 15px;
    border-width: 0;
    background-color: white;
    padding: 0 11px;
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.15s ease;
    font-family: "Roboto", sans-serif;

    &:hover {
      background-color: darken(#ffffff, 5%);
    }
  }

  .item.disabled {
    color: #9b9b9b;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
