.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  max-height: calc(100vh - 200px);
  min-height: 250px;
  height: auto;
  max-width: calc(100vw - 1000px);
  min-width: 730px;
  width: 100%;
  background-color: #fcfcfc;
  padding: 25px;
  overflow-y: auto;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;

  // &[data-is-contract="true"] {
  //   @media (min-width: 841px) {
  //     max-width: calc(100vw - 1000px);
  //   }
  // }

  @media (max-width: 840px) {
    max-height: 100%;
    position: fixed;
    max-width: none;
    top: 64px;
    left: 0;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    max-height: calc(100vh - 64px);
    height: calc(100vh - 64px);
    width: 100vw;
    box-sizing: border-box;
    min-width: 280px;
  }
}

.container::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.container::-webkit-scrollbar-track {
  background: transparent;
  margin: 15px;
}

.container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-right: none;
  border-left: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
