@import "../../mixins/mixins";

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  width: 100%;

  .modalElement {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .name {
    padding: 10px;
    width: 40%;
    color: rgb(117, 117, 117);
    align-self: center;
  }

  .fileName {
    padding-left: 10px;
    align-self: center;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .field {
    display: flex;
    align-self: center;
    padding-left: 30px;
    width: 100%;

    @media (min-width: 700px) {
      min-width: 300px;
    }
  }

  .uploadButton {
    @include button(rgba(38, 45, 137, 0.7));
    border-radius: 6px;
    max-height: 60px;
    max-width: 200px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: rgba(38, 45, 137, 0.8);
    }

    &:active {
      background-color: darken(rgba(38, 45, 137, 0.7), 3%);
    }

    span {
      padding-right: 7px;
      font-family: "Montserrat", sans-serif;
    }
  }

  @media (max-width: 840px) {
    .modalElement {
      flex-direction: column;
    }
    .name {
      width: 100%;
      padding: 15px 0;
    }
    .field {
      padding-left: 0;
    }
  }

  @media (max-width: 530px) {
    .uploadButton {
      span {
        font-size: 10px;
      }
    }
  }

  .line {
    height: 1px;
    border: 0;
    background: #e6e6e6;
    margin: 10px 0;
    width: 100%;
  }

  .textInfo {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 25px;
    // background: linear-gradient(
    //   rgba(42, 52, 199, 0.4),
    //   rgba(125, 31, 226, 0.286)
    // );
    background: linear-gradient(
      145deg,
      rgb(236, 229, 220),
      rgba(75, 39, 113, 0.286)
    );
    border-radius: 10px;
    box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.1);
    width: 90%;
    span {
      padding: 10px;
    }

    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }
  }
}
