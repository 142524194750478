@import "../../mixins/mixins";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form {
  font-family: "Montserrat", sans-serif;
  padding: 40px 0;

  .imgContainer {
    display: flex;
    justify-content: center;
    img {
      width: 120px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form {
      border-radius: 7px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      width: 400px;
      font-size: 15px;
      background-color: white;
      padding: 30px 40px;

      .inputContainer {
        display: flex;
        flex-direction: column;
        padding: 15px;
      }
      .textLink {
        display: flex;
        flex: 1;
        min-height: 50px;
        justify-content: flex-end;
        align-items: flex-end;
        align-self: flex-end;
        color: #969696;
        font-weight: 550;

        cursor: pointer;
        button {
          all: unset;
        }
      }

      .spanText,
      .spanText2 {
        @include reusable-button-text;
        align-self: center;
        margin: 0 0 20px 0;
      }
      .spanText2 {
        margin: 0;
      }
      .input {
        @include reusable-input;
        @include focus-style(#ccc);
        width: 100%;
        margin-bottom: 15px;
      }
      .button {
        @include reusable-button;
        @include interactive-bg(#fcfcfc);
        align-self: center;
        background-color: #fcfcfc;
        width: 200px;
        margin: 1rem 0 0 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    height: 100%;
    width: 100%;

    .content {
      .form {
        width: 100%;
        height: 100%;
        box-shadow: none;
        box-sizing: border-box;
        padding: 0;
        border-radius: 0;
      }
    }
  }
}
