.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  min-width: 280px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
}

.main {
  display: flex;
  // align-items: center;
  flex-grow: 1;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.content {
  flex-grow: 1;
  width: 75%;
  padding: 20px;
  @media (max-width: 910px) {
    width: 60%;
  }
  @media (max-width: 800px) {
    width: 100%;
    box-sizing: border-box;
  }
}
