.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  gap: 10px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}
