@mixin reusable-box($base-color: #e9bc5b) {
  border-width: 0;
  border-radius: 10px;
  background-color: var(--counter-bg-color, $base-color);
  box-shadow: var(--counter-box-shadow, 0px 2px 4px rgba(0, 0, 0, 0.2));
  transition: 0.6s ease;

  cursor: pointer;
}

@mixin interactive-bg($base-color: #fcfcfc) {
  &:hover {
    background-color: darken($base-color, 2%);
  }

  &:active {
    background-color: darken($base-color, 3%);
  }
}

@mixin form-element-style {
  border-width: 0;
  border-radius: 10px;
  width: 100%;
  padding: 0.5em;
  background-color: #ededed;
  cursor: pointer;
}

@mixin form-style {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.7rem;
  border-radius: 10px;
  width: 100%;
  background-color: #ededed;
}

@mixin focus-style($color) {
  &:focus {
    outline: none;
    border-color: $color;
    box-shadow: 0 0 5px $color;
  }
}

@mixin icon-style {
  margin-right: 10px;
  flex-shrink: 0;
}

@mixin form-input-style {
  display: flex;
  font-size: 0.95rem;
  border-width: 0;
  min-height: 40px;
  border-radius: 10px;
  background-color: #fcfcfc;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: flex-start;
  font-family: "Montserrat", sans-serif;
  background-color: rgba(255, 255, 255, 0.1);
}

@mixin reusable-address-box() {
  border-width: 0;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

@mixin reusable-button() {
  cursor: pointer;
  @include reusable-input;
  @include interactive-bg(#fcfcfc);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

@mixin reusable-button-text() {
  color: rgb(102, 102, 102);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

@mixin card-overlay {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  overflow: auto;
}

@mixin reusable-input {
  align-self: center;
  padding: 0 15px;
  border-width: 1px;
  border-style: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  border-color: #ccc;
  border-radius: 5px;
  min-height: 40px;
  font-size: 0.95rem;
  font-family: "Montserrat", sans-serif;
}

@mixin button($color) {
  display: flex;
  align-items: center;
  background-color: $color;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s;
}

@mixin btn-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 15px;
  gap: 10px;

  @media (max-width: 473px) {
    padding-top: 30px;
  }
}
