@import "../../mixins/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  top: 75px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  padding: 0 10px;
}

.headerText {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  padding: 15px;
  width: 95%;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  color: #386a9de0;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;

  .payer {
    padding: 15px;
    font-weight: 600;
  }

  .text {
    color: #7e7e7e;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: #0f4478de;
  }
}

.modalheader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 600px) {
    h2 {
      font-size: 18px;
    }
  }
}

.btnContainer {
  @include btn-container;
}

.err {
  color: red;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  gap: 10px;
}

.warnText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  color: #666;
  font-size: 18px;

  span {
    align-self: center;
  }

  .rotatingIcon {
    font-size: 40px;
    color: #666;
    animation: spin 3s linear infinite;
    margin-bottom: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}

.loaderContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
